import { createStore } from 'vuex'
import service from '@/service';
import router from '@/router';
export default createStore({
  state: {
    islogin:false,
    headtext:"基于 Vue 3 和 SpringBoot 3 的实训学员考试系统",
    // nations:[],
    tablist:[
      {id:1,text:"考试管理",to:"/exams",
        subtablist:[
          {id:1,text:"试卷管理",to:"/exams/sjgl"},
          {id:2,text:"考试管理",to:"/exams/ksgl"},
          {id:3,text:"考试过程",to:"/exams/ksgc"}
        ]
      },
      {id:2,text:"课程管理",to:"/course",
        subtablist:[
          {id:1,text:"教师管理",to:"/course/jsgl"},
          {id:2,text:"课程管理",to:"/course/kcgl"},
          {id:3,text:"题库管理",to:"/course/tkgl"}
        ]
      },
      {id:3,text:"学生管理",to:"/student",
        subtablist:[
          {id:1,text:"专业管理",to:"/student/zygl"},
          {id:2,text:"班级管理",to:"/student/bjgl"},
          {id:3,text:"学生信息",to:"/student/xsxx"},
          {id:4,text:"学生用户",to:"/student/xsyh"}
        ]
      },
      {id:4,text:"系统管理",to:"/system",
        subtablist:[
          {id:1,text:"民族管理",to:"/system/mzgl"},
          {id:2,text:"城市管理",to:"/system/csgl"},
          {id:3,text:"角色管理",to:"/system/jsgl1"},
          {id:4,text:"菜单管理",to:"/system/cdgl"},
          {id:5,text:"用户管理",to:"/system/yhgl"},
          {id:6,text:"题型管理",to:"/system/txgl"},
          {id:7,text:"学年学期管理",to:"/system/xnxq"},
          {id:8,text:"登录日志管理",to:"/system/dlrz"}
        ]
      }
    ]
  },
  getters: {
  },
  mutations: {
    login(state,islogin){
      router.push('/exams/sjgl')
      state.islogin = islogin
    },
    exams(state,data){
      state.exams = data;
    },
    nations(state,data){
      state.nations = data;
    },
    examdept(state,data){
      state.examdept = data;
    },
    examksgc(state,data){
      state.examprocess = data;
    }
  },
  actions: {
    login(state,user){
      service.post("/api/auth/login",user,{headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
      }})
    .then(res=>{
      state.commit("login",res.data.success)
    }).catch(err=>{
      console.log(err)
    })
    },
    nations(state){
      service.get("/nation/list")
    .then(res=>{
      state.commit("nations",res.data)
    }).catch(err=>{
      console.log(err)
    })
    },
    examsjgl(state,data){
      service.get("/exam/list",{
        params:data
      })
      .then(res=>{
        state.commit("exams",res.data)
      })
    },
    examksgl(state,data){
      service.get("/examdept/list",{
        params:data
      })
      .then(res=>{
        state.commit("examdept",res.data)
      })
    },
    examksgc(state,data){
      service.get("/examprocess/list",{
        params:data
      })
      .then(res=>{
        state.commit("examksgc",res.data)
      })
    }

  },
  modules: {
  }
})

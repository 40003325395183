import axios from 'axios';
 
// 配置新建一个 axios 实例
const service = axios.create({
	baseURL: 'http://47.97.118.131:8080',
	timeout: 50000,
	headers: { "Content-type": "application/json;charset=UTF-8" }
});
service.defaults.withCredentials = true
// 添加请求拦截器
service.interceptors.request.use(
	(config) => {

		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);
 
// 添加响应拦截器
service.interceptors.response.use(
	(response) => {
		
		return response;
	},
	(error) => {
		
		return Promise.reject(error);
	}
);
 
// 导出 axios 实例
export default service;
<template>
  <div class="app">
    <div class="top">
      <h1 class="title">{{ $store.state.headtext }}</h1>
      <div class="ta">
        <div class="ta_item" v-for="ta in $store.state.tablist" v-bind:key="ta.id">
          <router-link :to="{path:ta.to,query:{id:ta.id}}" active-class="router-link-active">{{ ta.text }}</router-link>
        </div>
      </div>
    </div>
    <div class="chilrenNav">
      <router-view/>
    </div>
  </div>
</template>
<script>
// 解决ERROR ResizeObserver loop completed with undelivered notifications.
// 重写ResizeObserver的构造函数，并在其中定义并调用防抖函数
window.ResizeObserver = class ResizeObserver extends window.ResizeObserver {
  constructor(callback) {
    let timer = null;
    const debouncedCallback = function () {
      let context = this;
      let args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, 16);
    };
    super(debouncedCallback);
  }
};

export default {
  mounted(){
    
  }
}

</script>
<style>

html {
  height: 100%;
  margin: 0px;
}
body {
  transition: background 0.25s ease-in-out;
  min-height: 100%;
  margin: 0px;
}
#app {
  height: 100%;
  width: 100%;
  max-height: 100%;
  min-width: 100%;
  position: relative;
}
.router-link-active {
  text-decoration: none;
  color: yellow;
}
a{
  text-decoration: none;
  color: white;
}
.app{
  height: 855px;
  width: 100%;
}
.title{
  text-align: center;
  padding-top: 40px;
  box-sizing: border-box;
  margin: 0;
}
.ta {
  display: flex;
  justify-content: flex-end;
}
.ta_item{
  padding: 20px;
}
.nav{
  color: black;
  text-decoration: none;
}
.top{
  background-color: #00adb5;
}
.chilrenNav{
  height: 100%;
}
</style>

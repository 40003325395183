import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: ()=> import('../views/login.vue')
  },
  {
    path: '/exams',
    name: 'exams',
    redirect:'/exams/sjgl',
    component: () => import('../views/Exams/ExamsVue.vue'),
    children:[
      {
        path: 'sjgl',
        name: 'sjgl',
        component: () => import('../views/Exams/ExamsSjgl.vue')
      },
      {
        path: 'ksgl',
        name: 'ksgl',
        component: () => import('../views/Exams/ExamsKsgl.vue')
      },
      {
        path: 'ksgc',
        name: 'ksgc',
        component: () => import('../views/Exams/ExamsKsgc.vue')
      }
    ]
  },
  {
    path: '/course',
    name: 'course',
    redirect:'/course/jsgl',
    component: () => import('../views/Course/CourseVue.vue'),
    children:[
      {
        path: 'jsgl',
        name: 'jsgl',
        component: () => import('../views/Course/CourseJsgl.vue')
      },
      {
        path: 'kcgl',
        name: 'kcgl',
        component: () => import('../views/Course/CourseKcgl.vue')
      },
      {
        path: 'tkgl',
        name: 'tkgl',
        component: () => import('../views/Course/CourseTkgl.vue')
      }
    ]
  },
  {
    path: '/student',
    name: 'student',
    redirect:'/student/zygl',
    component: () => import('../views/Student/StudentVue.vue'),
    children:[
      {
        path: 'zygl',
        name: 'zygl',
        component: () => import('../views/Student/StudentZygl.vue')
      },
      {
        path: 'bjgl',
        name: 'bjgl',
        component: () => import('../views/Student/StudentBjgl.vue')
      },
      {
        path: 'xsxx',
        name: 'xsxx',
        component: () => import('../views/Student/StudentXsxx.vue')
      },
      {
        path: 'xsyh',
        name: 'xsyh',
        component: () => import('../views/Student/StudentXsyh.vue')
      }
    ]
  },
  {
    path: '/system',
    name: 'system',
    redirect:'/system/mzgl',
    component: () => import('../views/System/SystemVue.vue'),
    children:[
      {
        path: 'mzgl',
        name: 'mzgl',
        component: () => import('../views/System/SystemMzgl.vue')
      },
      {
        path: 'csgl',
        name: 'csgl',
        component: () => import('../views/System/SystemCsgl.vue')
      },
      {
        path: 'jsgl1',
        name: 'jsgl1',
        component: () => import('../views/System/SystemJsgl.vue')
      },
      {
        path: 'cdgl',
        name: 'cdgl',
        component: () => import('../views/System/SystemCdgl.vue')
      },
      {
        path: 'yhgl',
        name: 'yhgl',
        component: () => import('../views/System/SystemYhgl.vue')
      },
      {
        path: 'txgl',
        name: 'txgl',
        component: () => import('../views/System/SystemTxgl.vue')
      },
      {
        path: 'xnxq',
        name: 'xnxq',
        component: () => import('../views/System/SystemXnxq.vue')
      },
      {
        path: 'dlrz',
        name: 'dlrz',
        component: () => import('../views/System/SystemDlrz.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router

import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import store from './store'

createApp(App).use(store).use(ElementPlus).use(router).mount('#app')

router.beforeEach((to, from,next) => {
    const routerlist=["/exams","/course","/student","/system"]
    var islogin=store.state.islogin
    if(routerlist.indexOf(to.path)>-1){
        if(islogin){
            next();
        }else{
            router.push("/login")
        }
    }else{
        next();
    }
  })
